export const clientNameFooter = "YOGA MULTIFLOW";
export const titleMain = "Praticar yoga é o seu momento de autocuidado.";
export const subtitleMain = "";

export const aspectRatioLogoAbout = '1'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "FERNANDA DAYRELL SILVESTRE";
export const nomeFornecedor = "YOGA MULTIFLOW";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/yogamultiflow/",
    profile: "@yogamultiflow"
  },
  // {
  //   type: "material-community",
  //   name: "whatsapp",
  //   link: "",
  //   number: ""
  // },
];

export const drawerClosedLinks = [
  {
    title: "Contato",
    link: "https://forms.gle/cJoo34H1A1f184Dz5",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/yoga-multiflow/id6446700362"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.mobile_yoga_multiflow.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];